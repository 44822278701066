<template>
    <div id="abnormal-warning" class="abnormal-warning">
        <sn-table-group
            :tableGroupAttributes="commonData"
            :commonMethods="commonMethods"
            @handleChange="handleChange"
        ></sn-table-group>
        <!-- 异常预警  -->
        <sn-dialog :dialogData="dialogDetail">
            <Dialog
                v-if="dialogDetail.dialog"
                @closeDialog="closeDialog"
                @changeTitle="changeTitleDetail"
                @openBhzsyhjzzPart="openBhzsyhjzzPart"
                :dialogInfo="dialogDetail.dialogInfo"
                :dialogDetail="dialogDetail"
            ></Dialog>
        </sn-dialog>
        <!-- 转为预警 -->
        <sn-dialog :dialogData="dialogWarn">
            <DialogTurnWarn
                @closeDialog="closeDialog"
                :dialogInfo="dialogWarn.dialogInfo"
            ></DialogTurnWarn>
        </sn-dialog>
    </div>
</template>
<script>
import { commonMethods } from "./table-common-methods";
import { mapActions, mapGetters } from "vuex";
import mixins from "./mixins";

export default {
    name: "abnormalwarning",
    mixins: [mixins],
    data() {
        return {
            commonMethods,
            pageindex: "1", // 当前页
            pagesize: "20", // 每页条数
        };
    },
    computed: {
        ...mapGetters(["system"]),
    },
    mounted() {
        let buttonData = this.commonData.snSearch.searchBtn.buttonData;
        // buttonData[buttonData.length - 1].operateType = "buttonToMap";
        // buttonData[buttonData.length - 1].name = "地图视图";
        buttonData[0].operateType = "buttonToMap";
        buttonData[0].name = "地图视图";
    },
    methods: {
        ...mapActions([
            "GetRcxcycjlList", // 异常预警列表
        ]),
        handleChange(type, value) {
            console.log(
                "%c 🍬 type, value: ",
                "font-size:20px;background-color: #6EC1C2;color:#fff;",
                type,
                value
            );
            switch (type) {
                case "buttonToMap": // 地图视图
                    this.toPage([{ path: "abnormalWarningMap" }]);
                    break;
                case "columnSee": // 点击查看详细按钮
                    this.dialogDetail.dialog = true;
                    this.dialogDetail.dialogInfo = value[0];
                    this.dialogDetail.title = "异常预警";
                    break;
                case "toAbnormal": // 转为预警
                    this.dialogWarn.dialog = true;
                    this.dialogWarn.dialogInfo = value[0];
                    break;
                case "columnMap": // 点击查看地图上的单个点
                    this.toMap([
                        { path: "abnormalWarningMap", value: value[0] },
                    ]);
                    break;
                case "page": // 切换当前页
                    this.pageindex = value[0];
                    this.getRecords();
                    break;
                case "treesearch":
                    if (value[1] === "YCLX") {
                        // 异常类型
                        this.tasktype = value[0];
                    }
                    if (value[1] === "SBRY") {
                        // 上报人
                        this.Gatherer = value[0];
                    }
                    this.pageindex = "1";
                    this.getRecords();
                    break;
                case "search":
                    if (value[1] === "keyword") {
                        // 关键字
                        this.keyword = value[0];
                    } else if (value[1] === "YCLX") {
                        // // 异常类型
                        this.tasktype = value[0];
                    } else if (value[1] === "SBRY") {
                        // 上报人
                        this.Gatherer = value[0];
                    } else if (value[1] === "YJZT") {
                        // 预警状态
                        this.status = value[0];
                    } else if (value[1] === "YJSJ") {
                        this.time = value[0];
                    }
                    this.pageindex = "1";
                    this.getRecords();
                    break;
            }
        },
        // 异常预警列表
        async getRecords() {
            let depmentid = "";
            if (this.system == "system2") {
                depmentid = localStorage.departmentId;
            }
            let result = await this.GetRcxcycjlList({
                keyword: this.keyword,
                tasktype: this.tasktype,
                Gatherer: this.Gatherer,
                time: this.time,
                status: this.status,
                pageindex: this.pageindex,
                pagesize: this.pagesize,
                depmentid: depmentid,
            });
            this.commonData.snTable.table.data = result.gridData;
            this.commonData.snPage.count = result.total;
        },
        toMap(val) {
            this.$router.push({
                name: val[0].path,
                params: {
                    WD: val[0].value.WD, // '22.1922627200'
                    JD: val[0].value.JD, // '113.5380886900'
                    ID: val[0].value.YCYSBM,
                    data: {
                        jcdxid: val[0].value.ID,
                    },
                },
            });
        },
        // 详情 转预警 title
        changeTitleDetail(title) {
            this.dialogDetail.title = title;
        },
        openBhzsyhjzzPart() {
            this.bhzzyhjzzgcjl = true;
        },
        closeBhzzyhjzzgcjl() {
            this.bhzzyhjzzgcjl = false;
        },
    },
};
</script>
<style lang="scss" scoped>
</style>