/* eslint-disable */
const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
            treeProps: {
                children: 'children',
                hasChildren: 'hasChildren'
            }
        },
        columns: {
            // 选择、序号
            columnsOperate: [{
                isShow: true,
                type: 'selection',
                width: 42
            },
            {
                isShow: true,
                label: '序号',
                type: 'index',
                width: 50
            }
            ],
            // 数据列
            columnsData: [{
                prop: 'YCLX_DESC',
                label: '异常类型',
                minWidth: 85
            }, {
                prop: 'SJMS',
                label: '情况描述',
                minWidth: 155
            }, {
                prop: 'MC',
                label: '遗产要素',
                minWidth: 105
            }, {
                prop: 'YCSFZZ_DESC',
                label: '是否追踪',
                minWidth: 70
            }, {
                prop: 'NAME',
                label: '上报人',
                minWidth: 85
            }, {
                prop: 'CJSJ',
                label: '预警时间',
                minWidth: 145
            }, {
                prop: 'PGNAME',
                label: '评估',
                minWidth: 80
            }, {
                prop: 'YJSFJC_DESC',
                label: '预警状态',
                minWidth: 80
            }, {
                prop: 'SFZYJ_DESC',
                label: '是否转化预警',
                minWidth: 80
            }],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    showMethod: 'showSeeBtn',
                    btnType: 'img',
                    operateType: 'columnSee',
                    title: '查看详情',
                    src: 'monitoring_cloud/detail.png',
                },
                {
                    isShow: true,
                    showMethod: 'showMapBtn',
                    btnType: 'img',
                    operateType: 'columnMap',
                    title: '地图',
                    src: 'monitoring_cloud/map.png',
                },
                {
                    isShow: true,
                    showMethod: 'showAbnormal',
                    btnType: 'img',
                    operateType: 'toAbnormal',
                    title: '转为预警',
                    src: 'working_desk/monitor_data/warn.png',
                },
                ]
            }
        }
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    // snCount: {
    //     eachtotal: [10, 20, 50, 100],
    //     defaultTiaoshu: 10
    // },
    snSearch: {
        inputData: [{
            // label: '关键字',
            placeholder: '关键字',
            value: 'keyword',
            operateType: 'search',
            isShow: true
        }],
        treeselectData: [{
            // label: '异常类型',
            clearable: true,
            placeholder: '异常类型',
            class: 'selectRound',
            width: '160px',
            list: [],
            optionLabel: 'label',
            optionValue: 'id',
            value: 'YCLX',
            operateType: 'treesearch',
            isShow: true
        }, {
            clearable: true,
            searchable: true,
            placeholder: '上报人',
            class: 'selectRound',
            width: '160px',
            list: [],
            optionLabel: 'label',
            optionValue: 'id',
            value: 'SBRY',
            operateType: 'treesearch',
            isShow: true
        }],
        selectData: [{
            // label: '异常类型',
            clearable: true,
            placeholder: '异常类型',
            class: 'selectRound',
            list: [],
            optionLabel: 'Name',
            optionValue: 'Id',
            value: 'YCLX',
            operateType: 'search',
            isShow: false
        },
        // {
        //     // label: '上报人',
        //     clearable: true,
        //     placeholder: '上报人',
        //     class: 'selectRound',
        //     width: '90px',
        //     list: [],
        //     optionLabel: 'REALNAME',
        //     optionValue: 'CJRID',
        //     value: 'SBRY',
        //     operateType: 'search',
        //     isShow: true
        // },
        {
            // label: '预警状态',
            clearable: true,
            placeholder: '预警状态',
            class: 'selectRound',
            width: '110px',
            list: [{
                ID: '0',
                NAME: '未解除'
            },
            {
                ID: '1',
                NAME: '已解除'
            },
            {
                ID: '2',
                NAME: '误报'
            }
            ],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'YJZT',
            operateType: 'search',
            isShow: true
        }
        ],
        dateData: [{
            isShow: true,
            label: '预警时间',
            clearable: true,
            placeholder: '预警时间',
            operateType: 'search',
            value: 'YJSJ'
        }],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'buttonExport',
                name: '地图视图',
                round: true,
                backColor: '#13CE67',
                color: '#fff'
            }]
        }
    }
};
export { commonData };