import { commonData } from './sn-table-group-common';
import Dialog from "./detail/index"; //异常预警弹框
import DialogTurnWarn from "./comp/turnWarn_dialog"; //异常预警弹框
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            commonData,
            dialogData: {
                title: "异常预警",
                dialog: false,
                tableData: null,
                class: "deepBlueDialog",
            },
            dialogDetail: {
                title: "异常预警",
                dialog: false,
                dialogInfo: null,
                class: "deepBlueDialog",
                width: "800px"
            },
            dialogWarn: {
                title: "转为预警",
                dialog: false,
                dialogInfo: null,
            },
            keyword: "", // 关键字
            tasktype: "", // 异常类型
            Gatherer: "", // 上报人
            status: "", // 预警状态
            time: "", // 预警时间
        };
    },
    props: {},
    components: {
        Dialog,
        DialogTurnWarn
    },
    computed: {
        ...mapGetters(["system"]),
    },
    created() { },
    methods: {
        ...mapActions([
            "GetRcxcycjlYclxList", // 异常类型列表
            "getReportPersonList", // 上报人列表
            "GetDepartmentUserTree", // 获取采集人列表
        ]),
        toPage(value) {
            this.$router.push({
                name: value[0].path
            });
        },
        closeDialog(val) {
            this.dialogDetail.dialog = false
            this.dialogWarn.dialog = false
            this.getRecords();
        },
        // 异常类型列表
        async getYclxList() {
            let result = await this.GetRcxcycjlYclxList();
            const props = {
                id: 'ID',
                label: 'NAME',
                children: 'Children',
            }
            if (result && result.length > 0) {
                result.forEach((item) => {
                    item.id = item.ID;
                    item.label = item.NAME;
                    if (item.Children) {
                        item.children = item.Children;
                        this.handleChild(item.children, props);
                    }
                });
                this.commonData.snSearch.treeselectData[0].list = result;
            }
        },
        handleChild(val, props) {
            if (val && val.length > 0) {
                val.forEach((ele) => {
                    ele.id = ele[props.id];
                    ele.label = ele[props.label];
                    if (ele[props.children] && ele[props.children].length > 0) {
                        ele.children = ele[props.children];
                        this.handleChild(ele.children, props);
                    }
                });
                return val;
            }
        },
        async getSbrList() {
            let result = await this.getReportPersonList({
                depid: this.system == "system2" ? localStorage.departmentId : ''
            });
            this.commonData.snSearch.selectData[1].list = result;
        },
        // 获取采集人列表
        async getDepartmentUserTree() {
            let result = await this.GetDepartmentUserTree();
            const props = {
                id: 'Id',
                label: 'Name',
                children: 'Children',
            }
            if (result && result.length > 0) {
                result.forEach((item) => {
                    item.id = item[props.id];
                    item.label = item[props.label];
                    if (item.Children && item.Children.length > 0) {
                        item.children = item.Children;
                        this.handleChild(item.children, props);
                    }
                });
                this.commonData.snSearch.treeselectData[1].list = result;
            }
        },
    },
    mounted() {
        // 清空入参
        // this.initQuery();
        // 获取表格数据/地图数据
        this.getRecords();

        // 获取异常类型列表
        this.getYclxList();
        // 获取上报人列表
        // this.getSbrList();
        this.getDepartmentUserTree();
    }
};