const commonMethods = {
    // 显示查看详细按钮
    showSeeBtn(row) {
        return true;
        // if (Number(row.SHZT) === 2) {
        // } else {
        //     return false;
        // }
    },
    // 显示地图查看按钮
    showMapBtn(row) {
        return true;
        // if (Number(row.SHZT) === 1) {
        // } else {
        //     return false;
        // }
    },
    // 显示转为异常
    showMapBtn(row) {
        return true;
        // if (Number(row.SHZT) === 1) {
        // } else {
        //     return false;
        // }
    },
    //  是否显示转为异常按钮
    showAbnormal(row) {
        // 未转化为异常  
        if (row.SFZYJ != '1') {
            return true;
        } else {
            return false;
        }
    },
    // 显示删除按钮
    // showDelBtn(row) {
    //     return true;
    // },
};
export { commonMethods };